import { Link } from "gatsby"
import React from "react"

function Footer() {
  return (
    <footer
      class="text-center  text-white "
      style={{ backgroundColor: "#223520" }}
    >
      <div
        class="py-3 px-4 sm:flex sm:justify-start md:flex md:items-center md:justify-between "
        style={{ backgroundColor: "#223520" }}
      >
        <span class="text-sm text-gray-300 sm:text-center">
          © 2022 Antaryami. All Rights Reserved.
        </span>
        <div
          class="flex mt-4 space-x-6 sm:text-center md:mt-0"
          style={{ marginLeft: "20vw" }}
        >
          <Link
            to="https://www.facebook.com/antaryamiyoga"
            target="_blank"
            className="text-gray-400 hover:text-white"
          >
            <svg
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                clipRule="evenodd"
              />
            </svg>
          </Link>

          <p class="text-sm text-gray-300 ">Call us: 9446386552 </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
