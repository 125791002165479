import Footer from "/src/components/footer"
import Seo from "/src/components/seo"
import TopNav from "/src/components/topnav"
import React from "react"

function Layout({ route, children }) {
  return (
    <div>
      <Seo title="Antaryami" />
      <TopNav route={route} />
      <main>{children}</main>
      <Footer />
    </div>
  )
}

export default Layout
