import Logo from "../images/logo-blue.png"
import "../styles/topnav.css"
import { Transition } from "@headlessui/react"
import { Link } from "gatsby"
import React, { useState } from "react"

function TopNav({ route }) {
  const [isOpen, setIsOpen] = useState(false)

  let pathname = route
  let opClass1 =
      " my-1  text-gray-700  dark:text-gray-200   dark:hover:text-indigo-400  md:mx-8 md:my-0",
    opClass2 =
      "my-1  text-gray-700  dark:text-gray-200   dark:hover:text-indigo-400  md:mx-8 md:my-0",
    opClass3 =
      "my-1  text-gray-700  dark:text-gray-200   dark:hover:text-indigo-400  md:mx-8 md:my-0",
    opClass4 =
      "my-1  text-gray-700  dark:text-gray-200   dark:hover:text-indigo-400  md:mx-8 md:my-0",
    opMob1 =
      "text-green-900 hover:bg-gray-400 hover:text-green-900 block px-3 py-2 rounded-md text-base font-medium",
    opMob2 =
      "text-green-900 hover:bg-gray-700 hover:text-green-900 block px-3 py-2 rounded-md text-base font-medium",
    opMob3 =
      "text-green-900 hover:bg-gray-700 hover:text-green-900 block px-3 py-2 rounded-md text-base font-medium",
    opMob4 =
      "text-green-900 hover:bg-gray-700 hover:text-green-900 block px-3 py-2 rounded-md text-base font-medium"

  switch (pathname) {
    case "/":
      opClass1 =
        "my-1  text-gray-700  dark:text-gray-200  f dark:hover:text-indigo-400  md:mx-8 md:my-0 activeNav"
      opMob1 =
        "text-green-900 hover:bg-gray-700 hover:text-green-900 block px-3 py-2 rounded-md text-base font-medium activeNav"
      break
    case "/courses":
      opClass2 =
        "my-1  text-gray-700  dark:text-gray-200   dark:hover:text-indigo-400  md:mx-8 md:my-0 activeNav"
      opMob2 =
        "text-green-900 hover:bg-gray-700 hover:text-green-900 block px-3 py-2 rounded-md text-base font-medium activeNav"
      break
    case "/instructors":
      opClass3 =
        "my-1  text-gray-700  dark:text-gray-200   dark:hover:text-indigo-400  md:mx-8 md:my-0 activeNav"
      opMob3 =
        "text-green-900 hover:bg-gray-700 hover:text-green-900 block px-3 py-2 rounded-md text-base font-medium activeNav"
      break

    case "/contact":
      opClass4 =
        "my-1  text-gray-700  dark:text-gray-200   dark:hover:text-indigo-400  md:mx-8 md:my-0 activeNav"
      opMob4 =
        "text-green-900 hover:bg-gray-700 hover:text-green-900 block px-3 py-2 rounded-md text-base font-medium activeNav"
      break
    default:
      opClass2 =
        "my-1  text-gray-700  dark:text-gray-200   dark:hover:text-indigo-400  md:mx-8 md:my-0 activeNav"
      opMob2 =
        "text-green-900 hover:bg-gray-700 hover:text-green-900 block px-3 py-2 rounded-md text-base font-medium activeNav"
  }


  return (
    <div>
      <nav className="topnav  fixed w-full z-10">
        <div className=" mx-5 px-4 sm:px-6 lg:px-8">
          <div className="flex items-center h-20">
            <div
              className="
   hidden md:block
      container
    
      py-3
      
      md:flex md:justify-between 
    "
            >
              <div class="flex items-center justify-between">
                <div>
                  <Link
                    class="
      
            text-xl
            font-bold
            text-gray-800
            dark:text-white
            md:text-2xl
            hover:text-gray-700
            dark:hover:text-gray-300
          "
                    to="/"
                  >
                    <img src={Logo} alt="Logo" height="70px" width="70px" />
                  </Link>
                </div>

                <div class="flex md:hidden">
                  <button
                    type="button"
                    class="
          sm:hidden
          md:block
            text-gray-500
            dark:text-gray-200
            hover:text-gray-600
            dark:hover:text-gray-400
            focus:outline-none focus:text-gray-600
            dark:focus:text-gray-400
          "
                    aria-label="toggle menu"
                  >
                    <svg viewBox="0 0 24 24" class="w-6 h-6 fill-current">
                      <path
                        fillRule="evenodd"
                        d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                      ></path>
                    </svg>
                  </button>
                </div>
              </div>

              <div
                className=" sm:hidden
          md:block items-center md:flex"
              >
                <div className="flex flex-col md:flex-row ">
                  <Link className={opClass1} to="/">
                    HOME
                  </Link>
                  <Link className={opClass2} to="/courses">
                    COURSES
                  </Link>
                  <Link className={opClass3} to="/instructors">
                    INSTRUCTORS
                  </Link>
                  <Link className={opClass4} to="/contact">
                    CONTACT
                  </Link>
                </div>
              </div>
            </div>

            <div className="-mr-2 flex md:hidden">
              <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                className=" inline-flex items-center justify-center p-2 rounded-md text-gray-800 hover:text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 "
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                <span className="sr-only"></span>
                {!isOpen ? (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="black"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                ) : (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="black"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>

        <Transition
          show={isOpen}
          enter="transition ease-out duration-100 transform"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          {ref => (
            <div className="md:hidden" id="mobile-menu">
              <div ref={ref} className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                <Link to="/" className={opMob1}>
                  Home
                </Link>

                <Link to="/courses" className={opMob2}>
                  Courses
                </Link>
                <Link to="/instructors" className={opMob3}>
                  Instructors
                </Link>

                <Link to="/contact" className={opMob4}>
                  Contact
                </Link>
              </div>
            </div>
          )}
        </Transition>
      </nav>
    </div>
  )
}

export default TopNav
